// App.js
import React, { lazy, Suspense, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { store } from "./utils/redux/store";
import { Provider } from "react-redux";
import CategoryData from "./components/CategoryData";
import { SpinnerDotted } from "spinners-react";

const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const Blog = lazy(() => import("./pages/Blog"));
const Header = lazy(() => import("./components/Header"));
const Footer = lazy(() => import("./components/Footer"));

const AppContent = () => {
  const [themeMode, setThemeMode] = useState(false);
  const handleData = (data) => {
    setThemeMode(data);
  };
  const isUnderMaintenance = true ;

  return isUnderMaintenance ? (
    <div
    style={{
      fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
      background: "linear-gradient(135deg, #3498db, #8e44ad)",
      color: "#2c3e50",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      margin: "0",
      padding: "20px",
      textAlign: "center",
    }}
  >
    <div
      style={{
        padding: "40px",
        backgroundColor: "#fff",
        borderRadius: "16px",
        boxShadow: "0 10px 25px rgba(0, 0, 0, 0.2)",
        maxWidth: "90%",
        width: "480px",
        transition: "transform 0.3s, box-shadow 0.3s",
      }}
    >
      <div style={{ fontSize: "60px", marginBottom: "20px" }}>🚧</div>
      <h1
        style={{
          fontSize: "36px",
          marginBottom: "15px",
          color: "#e74c3c",
          fontWeight: "700",
          letterSpacing: "1.5px",
          textTransform: "uppercase",
        }}
      >
        Maintenance Mode
      </h1>
      <p style={{ fontSize: "18px", lineHeight: "1.8", marginBottom: "30px", color: "#7f8c8d" }}>
        We're performing some upgrades to improve your experience. We'll be back
        shortly. Thank you for your patience and understanding.
      </p>
      <a
        href="https://www.shaileshattri.in"
        style={{
          textDecoration: "none",
          background: "linear-gradient(135deg, #e74c3c, #c0392b)",
          color: "#fff",
          padding: "12px 24px",
          borderRadius: "8px",
          fontSize: "16px",
          fontWeight: "600",
          boxShadow: "0 8px 15px rgba(231, 76, 60, 0.3)",
          transition: "transform 0.3s ease, box-shadow 0.3s ease",
          display: "inline-block",
        }}
        onMouseOver={(e) => {
          e.target.style.transform = "translateY(-3px)";
          e.target.style.boxShadow = "0 12px 20px rgba(231, 76, 60, 0.4)";
        }}
        onMouseOut={(e) => {
          e.target.style.transform = "translateY(0)";
          e.target.style.boxShadow = "0 8px 15px rgba(231, 76, 60, 0.3)";
        }}
      >
        Contact Support
      </a>
    </div>
  </div>
  ) : (
    <div
      className={`flex flex-col app ${
        !themeMode ? "dark-theme" : "light-theme"
      }`}
    >
      <Suspense
        fallback={
          <div className="flex items-center justify-center h-screen w-full text-2xl font-semibold text-violet-500">
            <SpinnerDotted
              size={50}
              thickness={100}
              speed={100}
              color="#36ad47"
            />
          </div>
        }
      >
        <Header sendData={handleData} />
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/blog" element={<Blog themeMode={themeMode} />} />
            <Route path="/work/*" element={<CategoryData />} />
          </Routes>
        </div>
        <Footer />
      </Suspense>
    </div>
  );
};

const App = () => (
  <Provider store={store}>
    <Router>
      <AppContent />
    </Router>
  </Provider>
);

export default App;
